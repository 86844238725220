import {
  ActiveUser,
  Card,
  CardsType,
  Location,
  Persona,
  Project,
  Request,
  RequestData,
  User,
  UserData,
  VisitType,
} from "./types";

const ApiPrefix: string = process.env.REACT_APP_API_URL_PREFIX || "";

export interface ProblemDetails {
  title: string;
  status: number;
  errors: Record<string, string>;
}

type Params = Record<string, string | string[]>;

function formUrl(path: string, params?: Params): string {
  const url: URL = new URL(path, ApiPrefix);
  for (const name in params) {
    const value = params[name];
    if (typeof value === "string") {
      url.searchParams.append(name, value);
    } else {
      for (const item of value) {
        url.searchParams.append(name, item);
      }
    }
  }
  return url.toString();
}

interface ApiCallParams {
  token: string;
  method: string;
  path: string;
  body?: object;
  params?: Params;
}

async function apiCallInner(params: ApiCallParams): Promise<Response> {
  const url: string = formUrl(params.path, params.params);
  const response: Response = await fetch(url, {
    method: params.method,
    headers: {
      Authorization: "Bearer " + params.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params.body),
  });
  return response;
}

export async function apiGetActiveUser(token: string): Promise<ActiveUser> {
  const response: Response = await apiCallInner({
    token: token,
    method: "GET",
    path: "activeuser",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: ActiveUser = await response.json();
  return data;
}

export async function apiGetCards(
  token: string,
  type: CardsType
): Promise<Card[]> {
  const response: Response = await apiCallInner({
    token: token,
    method: "GET",
    path: "cards",
    params: { type: type.toString() },
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Card[] = await response.json();
  return data;
}

export async function apiGetLocations(token: string): Promise<Location[]> {
  const response: Response = await apiCallInner({
    token: token,
    method: "GET",
    path: "requestlocation",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Location[] = await response.json();
  return data;
}

export async function apiGetProjects(token: string): Promise<Project[]> {
  const response: Response = await apiCallInner({
    token: token,
    method: "GET",
    path: "project",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Project[] = await response.json();
  return data;
}

export async function apiGetVisitTypes(token: string): Promise<VisitType[]> {
  const response: Response = await apiCallInner({
    token: token,
    method: "GET",
    path: "visittype",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: VisitType[] = await response.json();
  return data;
}

export async function apiGetRequest(
  token: string,
  requestNumber: number
): Promise<Request> {
  const response: Response = await apiCallInner({
    token: token,
    method: "GET",
    path: "requests/" + requestNumber,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Request = await response.json();
  return data;
}

export async function apiCreateRequest(
  token: string,
  requestData: RequestData
): Promise<ProblemDetails | null> {
  const response: Response = await apiCallInner({
    token: token,
    method: "POST",
    path: "requests",
    body: requestData,
  });
  if (response.status === 400) {
    const data: ProblemDetails = await response.json();
    return data;
  } else if (!response.ok) {
    const text = await response.text();
    throw Error(text ? text : response.statusText);
  }
  return null;
}

export async function apiUpdateRequest(
  token: string,
  requestNumber: number,
  requestData: RequestData
): Promise<ProblemDetails | null> {
  const response: Response = await apiCallInner({
    token: token,
    method: "PUT",
    path: "requests/" + requestNumber,
    body: requestData,
  });
  if (response.status === 400) {
    const data: ProblemDetails = await response.json();
    return data;
  } else if (!response.ok) {
    const text = await response.text();
    throw Error(text ? text : response.statusText);
  }
  return null;
}

export async function apiGetPersonas(token: string): Promise<Persona[]> {
  const response: Response = await apiCallInner({
    token: token,
    method: "GET",
    path: "users/personas",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: Persona[] = await response.json();
  return data;
}

export async function apiGetUsers(token: string): Promise<User[]> {
  const response: Response = await apiCallInner({
    token: token,
    method: "GET",
    path: "users",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: User[] = await response.json();
  return data;
}

export async function apiGetUser(token: string, userId: number): Promise<User> {
  const response: Response = await apiCallInner({
    token: token,
    method: "GET",
    path: "users/" + userId,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: User = await response.json();
  return data;
}

export async function apiCreateUser(
  token: string,
  userData: UserData
): Promise<ProblemDetails | null> {
  const response: Response = await apiCallInner({
    token: token,
    method: "POST",
    path: "users",
    body: userData,
  });
  if (response.status === 400) {
    const data: ProblemDetails = await response.json();
    return data;
  } else if (!response.ok) {
    const text = await response.text();
    throw Error(text ? text : response.statusText);
  }
  return null;
}

export async function apiUpdateUser(
  token: string,
  userId: number,
  userData: UserData
): Promise<ProblemDetails | null> {
  const response: Response = await apiCallInner({
    token: token,
    method: "PUT",
    path: "users/" + userId,
    body: userData,
  });
  if (response.status === 400) {
    const data: ProblemDetails = await response.json();
    return data;
  } else if (!response.ok) {
    const text = await response.text();
    throw Error(text ? text : response.statusText);
  }
  return null;
}

export async function apiDeleteUser(
  token: string,
  userId: number
): Promise<void> {
  const response: Response = await apiCallInner({
    token: token,
    method: "DELETE",
    path: "users/" + userId,
  });
  if (!response.ok) throw Error(response.statusText);
}
