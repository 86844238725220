export interface ActiveUser {
  email: string;
  name: string;
  persona: number;
  location: string;
}

export interface Card {
  cardId: string;
  cardName: string;
}

export enum CardsType {
  VISITORS = "visitors",
  LOANERS = "loaners",
}

export interface Location {
  locationName: string;
}

export interface Project {
  projNo: string;
  projName: string;
}

export interface VisitType {
  visitId: number;
  visitType: string;
}

export interface Request extends RequestData {
  requestNumber: string;
  requestedBy: string;
  canAssignBadges: boolean;
  canUpdate: boolean;
}

export interface RequestData {
  location: string;
  visitType: string;
  jobNo: string;
  guestCompany: string;
  entryDateTime: string;
  exitDateTime: string;
  workingSpace: string;
  wifiRequired: boolean;
  wifiDetails: string;
  noBadgeRequired: boolean;
  noBadgeJustification: string;
  notes: string;
  guests: Guest[];
}

export interface Guest {
  id: number;
  name: string;
  badgeId: string;
  badgeReturned: boolean;
}

export interface Persona {
  id: number;
  name: string;
}

export interface User extends UserData {
  id: number;
}

export interface UserData {
  name: string;
  email: string;
  persona: string;
}
