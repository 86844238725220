const ApiPrefix = process.env.REACT_APP_API_URL_PREFIX;

function invokeJsonCall(api, method, token, onComplete) {
    let invalid = false;

    fetch(ApiPrefix + api, {
        method: method,

        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((res) => {
            if (res.status >= 400) {
                invalid = true;
                return res.text();
            }

            return res.json();
        })
        .then((data) => {
            onComplete(invalid, data);
        });
}

function invokeJsonCallWithBody(api, method, token, body, onComplete) {
    let invalid = false;

    fetch(ApiPrefix + api, {
        method: method,

        body: JSON.stringify(body),

        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((res) => {
            if (res.status >= 400) {
                invalid = true;
                return res.text();
            }

            return res.json();
        })
        .then((data) => {
            onComplete(invalid, data);
        });
}

export function ApiCallGetActiveRequests(
    token,
    filter,
    e,
    v,
    j,
    enf,
    ent,
    onComplete
) {
    let api = "requests/list?active=true&filter=" + filter;

    if (enf) {
        enf = enf.toISOString();
    }

    if (ent) {
        ent = ent.toISOString();
    }

    if (filter === true) {
        let eFmt = encodeURIComponent(e);
        let vFmt = encodeURIComponent(v);
        let jFmt = encodeURIComponent(j);
        let enfFmt = encodeURIComponent(enf);
        let entFmt = encodeURIComponent(ent);

        api +=
            "&e=" +
            eFmt +
            "&v=" +
            vFmt +
            "&j=" +
            jFmt +
            "&enf=" +
            enfFmt +
            "&ent=" +
            entFmt;
    }

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallGetInactiveRequests(
    token,
    filter,
    e,
    v,
    j,
    enf,
    ent,
    onComplete
) {
    let api = "requests/list?active=false&filter=" + filter;

    if (enf) {
        enf = enf.toISOString();
    }

    if (ent) {
        ent = ent.toISOString();
    }

    if (filter === true) {
        let eFmt = encodeURIComponent(e);
        let vFmt = encodeURIComponent(v);
        let jFmt = encodeURIComponent(j);
        let enfFmt = encodeURIComponent(enf);
        let entFmt = encodeURIComponent(ent);

        api +=
            "&e=" +
            eFmt +
            "&v=" +
            vFmt +
            "&j=" +
            jFmt +
            "&enf=" +
            enfFmt +
            "&ent=" +
            entFmt;
    }

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallDeleteRequest(token, id, onComplete) {
    let api = "requests/delete?id=" + id;

    invokeJsonCall(api, "DELETE", token, onComplete);
}

export function ApiCallGetVisitTypes(token, onComplete) {
    let api = "visittype";

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallGetProjects(token, onComplete) {
    let api = "project";

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallGetCards(token, forVisitors, onComplete) {
    let api = "cards";

    if (forVisitors === true) api += "?type=visitors";
    else api += "?type=loaners";

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallGetLoanerBadges(token, filter, e, b, enf, onComplete) {
    let api = "loanerbadges/list?active=true&filter=" + filter;

    if (enf) {
        enf = enf.toISOString();
    }

    if (filter === true) {
        let eFmt = encodeURIComponent(e);
        let bFmt = encodeURIComponent(b);
        let enfFmt = encodeURIComponent(enf);

        api += "&e=" + eFmt + "&b=" + bFmt + "&enf=" + enfFmt;
    }

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallGetInactiveLoanerBadges(
    token,
    filter,
    e,
    b,
    enf,
    onComplete
) {
    let api = "loanerbadges/list?active=false&filter=" + filter;

    if (enf) {
        enf = enf.toISOString();
    }

    if (filter === true) {
        let eFmt = encodeURIComponent(e);
        let bFmt = encodeURIComponent(b);
        let enfFmt = encodeURIComponent(enf);

        api += "&e=" + eFmt + "&b=" + bFmt + "&enf=" + enfFmt;
    }

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallGetLoanerBadge(token, badgeId, onComplete) {
    let api = "loanerbadges/get?badgeId=" + badgeId;

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallValidateLoanerBadge(token, data, onComplete) {
    let api = "loanerbadges/validate";

    invokeJsonCallWithBody(api, "POST", token, data, onComplete);
}

export function ApiCallAddLoanerBadge(token, data, onComplete) {
    let api = "loanerbadges/post";

    invokeJsonCallWithBody(api, "POST", token, data, onComplete);
}

export function ApiCallUpdateLoanerBadge(token, data, onComplete) {
    let api = "loanerbadges/update";

    invokeJsonCallWithBody(api, "PUT", token, data, onComplete);
}

export function ApiCallDeleteLoanerBadge(token, id, onComplete) {
    let api = "loanerbadges/delete?id=" + id;

    invokeJsonCall(api, "DELETE", token, onComplete);
}

export function ApiCallGetEmployees(token, onComplete) {
    let api = "employees";

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallGetActiveUser(token, onComplete) {
    let api = "activeuser";

    invokeJsonCall(api, "GET", token, onComplete);
}
